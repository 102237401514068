<template>
  <b10-base>
    <v-text-field
      v-model="form.nombre"
      clearable
      flat
      label="Nombre"
      :rules="formRules.nombre"
    />
    <b10-autocomplete
      v-model="form.idttelefono"
      :items="ttelefonos"
      item-value="idttelefono"
      item-text="descripcion"
      label="Tipo de contacto"
      clearable
      :rules="formRules.idttelefono"
    />
    <v-text-field
      v-model="form.telefono"
      clearable
      flat
      label="Teléfono"
      :rules="formRules.telefono"
    />
    <v-text-field
      v-model="form.email"
      clearable
      flat
      label="Correo electrónico"
      :rules="formRules.email"
    />
    <v-text-field
      v-model="form.horario"
      clearable
      flat
      label="Horario"
    />
    <b10-subheader
      label="Para notificaciones de "
      :icon="$vuetify.icons.values.send"
    />
    <v-checkbox
      v-model="form.email_notifica_sat"
      label="Servicio técnico"
    />
    <v-checkbox
      v-model="form.email_notifica_comercial"
      label="Comerciales"
    />
    <v-checkbox
      v-model="form.email_notifica_admon"
      label="Administración"
    />
    <template
      v-if="esContactoDeSistema"
    >
      <b10-subheader
        label="Datos de CRA"
        :icon="$vuetify.icons.values.novedad"
      />
      <v-text-field
        v-model.number="form.orden_cra"
        clearable
        flat
        label="Orden CRA"
        type="number"
      />
      <v-text-field
        v-if="hasPermVerPersonasContactoCodigoCRA"
        v-model="form.codigo_cra"
        flat
        label="Código CRA"
        :append-icon="isInsert ? null : showingCodigoCRA ? $vuetify.icons.values.hide : $vuetify.icons.values.show"
        :type="showingCodigoCRA ? 'text' : 'password'"
        @click:append="clickShowingCodigoCRA"
      />
      <v-text-field
        v-if="hasPermVerPersonasContactoCodigoCRA"
        v-model="form.codigo_coaccion_cra"
        flat
        label="Código de coacción CRA"
        :append-icon="isInsert ? null : showingCodigoCoaccionCRA ? $vuetify.icons.values.hide : $vuetify.icons.values.show"
        :type="showingCodigoCoaccionCRA ? 'text' : 'password'"
        @click:append="clickShowingCodigoCoaccionCRA"
      />
    </template>
    <b10-subheader
      label="Otros datos"
      :icon="$vuetify.icons.values.info"
    />
    <v-checkbox
      v-if="hasPermPersonaContactoParaTodosLosSistemas"
      v-model="form.para_todos_los_sistemas"
      label="Para todos los sistemas."
    />
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ClienteTelefonoFormData'
import { regexEmail } from '@/utils/regex'
import { PARAMETRO } from '@/utils/consts'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  props: {
    idsistema: {
      type: [Number, String],
      default: '',
    },
    hasPermVerPersonasContactoCodigoCRA: {
      type: Boolean,
      default: false,
    },
    hasPermVerPersonasContactoCodigoCoaccionCRA: {
      type: Boolean,
      default: false,
    },
    hasPermPersonaContactoParaTodosLosSistemas: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        nombre: null,
        idttelefono: null,
        telefono: null,
        email: null,
        horario: null,
        email_notifica_sat: true,
        email_notifica_comercial: true,
        email_notifica_admon: false,
        orden_cra: null,
        codigo_cra: null,
        codigo_coaccion_cra: null,
        observaciones: null,
        para_todos_los_sistemas: false,
      },
      formRules: {
        nombre: [
          v => !!v || 'Campo requerido'
        ],
        idttelefono: [
          v => !!v || 'Campo requerido'
        ],
        email: [
          v => !v || regexEmail.test(v) || 'El correo electrónico no es válido',
        ],
      },
      ttelefonos: [],
      showingCodigoCRA: false,
      showingCodigoCoaccionCRA: false,
      idsistema_telefono: null,
    }
  },
  computed: {
    esContactoDeSistema () {
      return !!this.idsistema && (this.isInsert || !!this.idsistema_telefono)
    },
  },
  async created () {
    const parametrosNecesarios = [ PARAMETRO.parametros.TIPO_TELEFONO_DEFECTO ]
    this.showingCodigoCRA = this.isInsert
    this.showingCodigoCoaccionCRA = this.isInsert
    const resp = await Data.selectLookups(this, parametrosNecesarios)
    this.ttelefonos = resp.data.selectTipoTelefono.result.dataset
    const tipoTelefonoDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.TIPO_TELEFONO_DEFECTO })
    if (this.isInsert && tipoTelefonoDefecto) {
      this.$set(this.form, 'idttelefono', parseInt(tipoTelefonoDefecto.valor))
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id, this.idsistema)
      this.$set(this.form, 'nombre', formData.nombre)
      this.$set(this.form, 'idttelefono', formData.idttelefono)
      this.$set(this.form, 'telefono', formData.telefono)
      this.$set(this.form, 'email', formData.email)
      this.$set(this.form, 'horario', formData.horario)
      this.$set(this.form, 'email_notifica_sat', formData.email_notifica_sat)
      this.$set(this.form, 'email_notifica_comercial', formData.email_notifica_comercial)
      this.$set(this.form, 'email_notifica_admon', formData.email_notifica_admon)
      this.$set(this.form, 'orden_cra', formData.orden_cra)
      this.$set(this.form, 'codigo_cra', formData.codigo_cra)
      this.$set(this.form, 'codigo_coaccion_cra', formData.codigo_coaccion_cra)
      this.$set(this.form, 'observaciones', formData.observaciones)
      this.$set(this.form, 'para_todos_los_sistemas', formData.para_todos_los_sistemas)
      this.idsistema_telefono = formData.idsistema_telefono
    },
    async clickShowingCodigoCRA () {
      if (this.isEdit) {
        if (!this.showingCodigoCRA) {
          this.$loading.showManual('Auditando acceso...')
          try {
            await this.$online.auditoria.auditarViewRecordClienteTelefono(this.id)
            if (this.idsistema_telefono) {
              await this.$online.auditoria.auditarViewRecordSistemaTelefono(this.idsistema_telefono)
            }
          } finally {
            this.$loading.hide()
          }
          this.showingCodigoCRA = true
        } else {
          this.showingCodigoCRA = false
        }
      }
    },
    async clickShowingCodigoCoaccionCRA () {
      if (this.isEdit) {
        if (!this.showingCodigoCoaccionCRACRA) {
          this.$loading.showManual('Auditando acceso...')
          try {
            await this.$online.auditoria.auditarViewRecordClienteTelefono(this.id)
            if (this.idsistema_telefono) {
              await this.$online.auditoria.auditarViewRecordSistemaTelefono(this.idsistema_telefono)
            }
          } finally {
            this.$loading.hide()
          }
          this.showingCodigoCoaccionCRA = true
        } else {
          this.showingCodigoCoaccionCRA = false
        }
      }
    },
  }
}
</script>
